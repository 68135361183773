import {Helmet} from 'react-helmet-async';
import {connect} from 'react-redux';
import React, {Component} from 'react';

import MarketLayout from 'web/components/market_layout';

class UnsubscribeConfirmationPage extends Component {
  static reducer(state, action) {
    return MarketLayout.reducer(state, action);
  }

  render() {
    let subject;
    switch (this.props.unsubscribePage.emailType) {
      case 'prompt_emails':
        subject = 'shopping reminders';
        break;
      case 'newsletter':
        subject = 'our cooking inspiration newsletter';
        break;
      default:
        subject = 'something... maybe';
    }

    return (
      <MarketLayout
        categories={this.props.categories}
        foodhub={this.props.foodhub}
        user={this.props.user}
        disableUpcomingOrdersBanner
      >
        <Helmet>
          <title>You Have Unsubscribed | Good Eggs</title>
        </Helmet>

        <div className="unsubscribe-confirmation-page">
          <h1 className="unsubscribe-confirmation-page__title">
            You’ve been unsubscribed from {subject}.
          </h1>
          <div className="unsubscribe-confirmation-page__body">
            If you change your mind, you can always turn them back on from{' '}
            <a className="unsubscribe-confirmation-page__link" href="/account">
              your account
            </a>
            .
          </div>
          <a className="unsubscribe-confirmation-page__cta" href={`/produce`}>
            Shop the Market
          </a>
        </div>
      </MarketLayout>
    );
  }
}

UnsubscribeConfirmationPage.pageName = 'Unsubscribed';

export default connect((state) => state)(UnsubscribeConfirmationPage);
